<template>
  <div
      class="app-container"
      v-loading.fullscreen="isLoading"
      element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-row class="row-card">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
          <h2 class="heading page-heading">
            {{ $t(`page_subscription_renew.title`) }}
          </h2>
          <div class="unit-info">
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="home"/>
              <span>
                  {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.unit_title`) }}
              </span>
              <span>
                  &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                  {{ subscription.unitTitle }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <svg v-svg symbol="adoption_icon" />
              <span>
                  {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.bricks_to_subscribe`) }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                  {{ $tc(
                  `page_subscription_renew.card_info.${subscription.unitType}.bricks`,
                  subscription.subscriptionChildren,
                  {count: subscription.subscriptionChildren})
                }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="hourglass_top"/>
              <span>
                  {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.expiration_date`) }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                  {{ subscription.dateEnd }}
              </span>
            </div>
            <div v-if="!arePricesEqual" class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="attach_money"/>
              <span>
                  {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.price_old`) }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ subscription.oldPrice }}
                {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.price_unit`) }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="attach_money"/>
              <span>
                  {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.price_new`) }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ subscription.newPrice }}
                {{ $t(`page_subscription_renew.card_info.${subscription.unitType}.price_unit`) }}
              </span>
            </div>
          </div>
          <div v-if="!arePricesEqual" class="mt-1">
            <el-radio-group v-model="isNewPricing">
              <el-radio :label="true">
                {{ $t('page_subscription_renew.pricing.label_new') }}
              </el-radio>
              <el-radio :class="{ 'mt-1': isMd }" :label="false">
                {{ $t('page_subscription_renew.pricing.label_old') }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row class="row-card" v-if="isLoggedIn">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
          <el-form
              label-position="top"
              size="medium"
              status-icon
          >
            <el-row>
              <el-col>
                <el-form-item :label="$t('page_adoption_payment.fields.months')">
                  <div class="el-radio-group--grid">
                    <el-radio
                        v-for="(opt, idx) in $options.MONTHS_COUNT_OPTIONS"
                        :key="idx"
                        v-model.number="selectedMonthsCount"
                        :label="opt.value" border
                    >
                      {{ $tc('page_adoption_payment.fields.radio_month', opt.pluralization, { count: opt.value }) }}
                    </el-radio>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <SubscriptionCalculator
                    is-one-time-payment
                    :children-count="subscription.subscriptionChildren"
                    :months-count="selectedMonthsCount"
                    :monthly-cost="monthlyCost"
                    :unit-type="subscription.unitType"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-button type="primary" @click="goToPayment">
                  {{ $t('page_adoption_payment.btn_submit') }}
                </el-button>
                <el-button @click="backToProfile">
                  {{ $t('page_adoption_payment.btn_cancel') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <PaymentProviderCard v-if="isLoggedIn"/>
  </div>
</template>

<script>
import PaymentProviderCard from '../shared/components/PaymentProviderCard'
import SubscriptionCalculator from '../shared/components/SubscriptionCalculator'
import VIcon from '../../components/ui/v-icon/VIcon'

import {mapGetters} from 'vuex'
import {PROFILE_ORDERS_ROUTE_NAME} from '../../shared/constants/app-routes-names'

import {
  fetchSubscriptionInfo,
  renewSubscription
} from '../shared/services/payment-service'


import {MONTHS_COUNT_OPTIONS} from '../shared/constants/countOptions'
import {UNIT_TYPE_FAMILY} from '../../admin/units/shared/constants/units-constants'

export default {
  name: 'RenewSubscription',
  MONTHS_COUNT_OPTIONS,
  components: {
    PaymentProviderCard,
    SubscriptionCalculator,
    VIcon
  },
  inject: ['responsive'],
  data() {
    return {
      selectedMonthsCount: 3,
      isLoading: false,
      isNewPricing: true,
      subscription: {
        monthlyCost: 0,
        subscriptionChildren: 0,
        unitType: UNIT_TYPE_FAMILY
      }
    }
  },
  created() {
    this.getSubscriptionInfo()
  },
  methods: {
    backToProfile() {
      this.$router.push({
        name: PROFILE_ORDERS_ROUTE_NAME,
      })
    },
    goToPayment() {
      const {subscriptionID} = this.$route.params
      const {selectedMonthsCount, isNewPricing} = this
      const payload = {
        subscriptionID,
        selectedMonthsCount,
        isNewPricing
      }
      this.isLoading = true
      renewSubscription(payload)
        .then((redirectUrl) => {
          window.location.href = redirectUrl
        })
        .catch(() => {
          this.$message({
            message: `${this.$t('alerts.common_error')}`,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSubscriptionInfo() {
      const { subscriptionID } = this.$route.params
      this.isLoading = true
      fetchSubscriptionInfo(subscriptionID)
        .then((res) => {
          this.subscription = res
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn'
    }),
    isMd() {
      return this.responsive.isMd
    },
    monthlyCost() {
      const {oldPrice, newPrice} = this.subscription
      return this.isNewPricing ? newPrice : oldPrice
    },
    arePricesEqual() {
      const {newPrice, oldPrice} = this.subscription
      return newPrice === oldPrice
    }
  }
}
</script>

<style scoped>

</style>
